/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKeyList'
})
export class EnumKeyListPipe implements PipeTransform {

    transform(enumType: any): any {
        let values = Object.values(enumType)
        return values.slice(0, values.length / 2);
    }

}
