/*
 * *****************************************************************************
 *     Copyright (C)  Motorola Solutions, INC.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { createAction, props } from '@ngrx/store';
import { ACDRejectionEvent, AgentStatus, AgentStatusRecord, CadLogoutRequest, ConnectionStatus, UserAuthenticationRecord } from 'CalltakingCoreApi';
import { BidResult } from '../model/bid-result';
import { VestaKeycloakTokenParsed } from '../model/vesta-keycloak-token-parsed';
import { Bid } from '../model/bid';

export const initUser = createAction(
    '[User] Initializing User',
    props<{ token: string; tokenParsed: VestaKeycloakTokenParsed | undefined }>()
);
export const updateUserSubscriptions = createAction(
    '[User] Stomp Status Update',
    props<{ subscriptions: { [topic: string]: { [receipt: string]: boolean } } }>()
);
export const initializeUserState = createAction('[User] State Initialization', props<{ clusterName: string; initializationTime: number }>());
export const updateApplicationPresence = createAction('[User] Application Presence', props<{ loggedIn: boolean; loginTime?: number }>());
export const connectionStatus = createAction('[User] Reporting Media Status', props<{ status: { [asterisk: string]: ConnectionStatus } }>());
export const connectionStatusSuccess = createAction('[User] Reporting Media Status Success');
export const connectionStatusFail = createAction('[User] Reporting Media Status Fail', props<{ payload: string }>());
export const requestedAcdStatusChange = createAction('[User] Requesting ACD Status Change', props<{ agentStatus: AgentStatus; overrideHeldCalls?: boolean; isAgentRequested?: boolean }>());
export const acdStatusChange = createAction('[User] ACD State Change', props<{ agentStatus: AgentStatus, isAgentRequested?: boolean }>());
export const acdStatusChangeSuccess = createAction('[User] ACD State Change Success', props<{ agentStatus: AgentStatusRecord }>());
export const acdStatusChangeFail = createAction('[User] ACD State Change Fail', props<{ payload: string }>());
export const setIgnoredAcdCalls = createAction('[User] ACD Add Ignored Calls', props<{ calls: string[] }>());
export const removeIgnoredAcdCall = createAction('[User] ACD Remove Ignored Calls', props<{ callId: string }>());
export const acdBid = createAction('[User] ACD Bid', props<{ bid: Bid }>());
export const acdBidResponse = createAction('[User] ACD Bid Response', props<{ result: BidResult }>());
export const acdBidFail = createAction('[User] ACD Bid Fail', props<{ payload: string }>());
export const acdBidRemove = createAction('[User] ACD Bid Remove', props<{ callId: string }>());
export const acdAnswerRequest = createAction('[User] ACD Answer Request', props<{ bid: BidResult }>());
export const acdAnswerRequestSuccess = createAction('[User] ACD Answer Request Success', props<{ callId: string; nenaId: string; clusterName: string }>());
export const acdAnswerRequestFail = createAction('[User] ACD Answer Request Fail', props<{ callId: string; payload: string }>());
export const acdAnswerComplete = createAction('[User] ACD Answer Complete', props<{ callId: string; clusterName: string }>());
export const acdAnswerCompleteFailed = createAction('[User] ACD Answer Complete Failed', props<{ callId: string; clusterName: string }>());
export const acdRequestRejection = createAction('[User] ACD Request Rejection', props<{ callId: string }>());
export const acdRequestRejectionSuccess = createAction('[User] ACD Rejection Success', props<{ callId: string }>());
export const acdRequestRejectionFail = createAction('[User] ACD Rejection Fail', props<{ payload: string }>());
export const acdRejection = createAction('[User] ACD Rejection', props<{ event: ACDRejectionEvent }>());
export const fetchUserAuthenticationRecords = createAction('[User] Authentication Records Fetch', props<{ clusterName: string }>());
export const fetchUserAuthenticationRecordsSuccess = createAction(
    '[User] Authentication Records Fetch Success',
    props<{ clusterName: string; users: UserAuthenticationRecord[] }>()
);
export const fetchUserAuthenticationRecordsFail = createAction('[User] Authentication Records Fetch Fail', props<{ payload: string }>());
export const newUserAuthenticationRecord = createAction('[User] New Authentication Record', props<{ userAuthenticationRecord: UserAuthenticationRecord }>());
export const updateUserAuthenticationRecord = createAction(
    '[User] Update Authentication Record',
    props<{ userAuthenticationRecord: UserAuthenticationRecord }>()
);
export const deleteUserAuthenticationRecord = createAction(
    '[User] Delete Authentication Record',
    props<{ userAuthenticationRecord: UserAuthenticationRecord }>()
);
export const setRole = createAction('[User] Update selected Role', props<{ role: string }>());
export const remoteLogoutEvent = createAction('[User] Auto Logout Event', props<{ logoutRequest: CadLogoutRequest }>());

export const languageSelect = createAction('[User] Language Select', props<{ language: string }>());
export const updateCurrentRole = createAction('[User] Update Current Role', props<{ role: string }>());
export const updateCurrentRoleSuccess = createAction('[User] Update Current Role Success');
export const noAction = createAction('[User] No Action');
