/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { EnvironmentService } from '../services/environment.service';
import { KeycloakService } from 'keycloak-angular';
import { SessionGuardService } from '../../session-guard.service';
import { IEnvironment } from '../../../assets/config/environment';
import { KeycloakOidcProviderService } from '../services/keycloak-oidc-provider.service';

export function AppInitializer(envService: EnvironmentService, keycloak: KeycloakService, oidcProviderService: KeycloakOidcProviderService, sessionGuardService: SessionGuardService) {
    return () =>
        new Promise(async (resolve, reject) => {
            let config: IEnvironment;
            try {
                config = await envService.loadConfiguration();
            } catch (error) {
                console.error('err', error);
                reject('Failure to load client configuration.');
                return;
            }
            await sessionGuardService.canInitialize().catch((reason) => {
                reject(reason);
                return;
            });

            // oidcProviderService is used to override keycloak endpoints in order to use federation specific token refresh urls
            // this allows tokens to be refreshed during a federation outage
            // wait for initial keycloak health check to pass via initialized promise
            // in order to verify the initially selected federation url is reachable on keycloak.init
            await oidcProviderService.initialized;

            try {
                await keycloak.init({
                    config: {
                        url: config.AUTH_URL,
                        realm: config.AUTH_REALM,
                        clientId: config.AUTH_CLIENT_ID,
                        //@ts-ignore
                        oidcProvider: config.featureFlags?.ccAdminIntegrated ? undefined : oidcProviderService
                    },
                    initOptions: {
                        checkLoginIframe: true,
                        checkLoginIframeInterval: 5
                    },
                    enableBearerInterceptor: false,
                    bearerExcludedUrls: ['https://idm.pi.commandcentral.com', 'https://admin-api.pi.commandcentral.com', 'idm.imw.motorolasolutions.com', 'https://admin-api.usgov.commandcentral.com']
                });
                console.log('resolving keycloak...');
                resolve('');
            } catch (err) {
                console.error(`Error during initialization: ${JSON.stringify(err)}`);
                reject('Failure to resolve keycloak.');
            }
        });
}
