/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeatureSelector, createSelector, createSelectorFactory, DefaultProjectorFn, resultMemoize, select } from '@ngrx/store';
import {
    LocationState,
    selectAllEnhancedLocationsReducer,
    selectAllStandardLocationsReducer,
    selectEnhancedLocationEntities,
    selectEnhancedLocationState,
    selectStandardLocationEntities,
    selectStandardLocationState
} from './location.reducer';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocationDisplayTemplate } from '../model/location-item-template';
import { selectCallRedialUuid } from '../../call/+state/call.selectors';
import { ServiceResponderEsn, ServiceResponderUrn } from '../model/service-responders';
import { Call } from 'CalltakingCoreApi';
import { LocationEvent } from '../model/location';
import { CallbackUtil } from '../../call/util/callback-util';

export const LOCATION_FEATURE = 'location';

export const selectLocationState = createFeatureSelector<LocationState>(LOCATION_FEATURE);
export const selectLocationEffectInitialized = createSelector(selectLocationState, (state) => state.initialized);

export const selectLocationStateStandard = createSelector(selectLocationState, selectStandardLocationState);
export const selectLocationStateEnhanced = createSelector(selectLocationState, selectEnhancedLocationState);

export const selectStandardLocationMap = createSelector(selectLocationStateStandard, selectStandardLocationEntities);
export const selectEnhancedLocationMap = createSelector(selectLocationStateEnhanced, selectEnhancedLocationEntities);

export const selectAllStandardLocations = createSelector(selectLocationStateStandard, selectAllStandardLocationsReducer);
export const selectAllEnhancedLocations = createSelector(selectLocationStateEnhanced, selectAllEnhancedLocationsReducer);

export const selectStandardLocationByCallMap = createSelector(selectLocationState, (state) => state.standardLocationCallMap);

export const selectEnhancedLocationByCallMap = createSelector(selectLocationState, (state) => state.enhancedLocationCallMap);

export const arrayFilterMemoize = (projectorFn: DefaultProjectorFn<Call[]>) => resultMemoize(projectorFn, arrayIsEqual);

export function arrayIsEqual(a: any[], b: any[]): boolean {
    return a?.length === b?.length && a?.every((item) => b?.includes(item));
}

export const selectStandardLocationEvents = (callId: string) =>
    createSelectorFactory<object, LocationEvent[]>(arrayFilterMemoize)(
        selectStandardLocationByCallMap,
        selectCallRedialUuid(callId),
        (locationByCallMap: { [callId: string]: { [locationId: string]: LocationEvent } }, redialUuid: string) =>
            CallbackUtil.getSortedCallLocationEvents(callId, redialUuid, locationByCallMap)
    );

export const selectEnhancedLocationEvents = (callId: string) =>
    createSelectorFactory<object, LocationEvent[]>(arrayFilterMemoize)(
        selectEnhancedLocationByCallMap,
        selectCallRedialUuid(callId),
        (locationByCallMap: { [callId: string]: { [locationId: string]: LocationEvent } }, redialUuid: string) =>
            CallbackUtil.getSortedCallLocationEvents(callId, redialUuid, locationByCallMap)
    );

export const selectStandardLocationEvent = (callId: string) => createSelector(selectStandardLocationEvents(callId), (locationEvents) => locationEvents[0]);
export const selectEnhancedLocationEvent = (callId: string) => createSelector(selectEnhancedLocationEvents(callId), (locationEvents) => locationEvents[0]);
export const selectStandardLocation = (callId: string) => createSelector(selectStandardLocationEvent(callId), (locationEvent) => locationEvent?.location);

export const selectEnhancedLocation = (callId: string) => createSelector(selectEnhancedLocationEvent(callId), (locationEvent) => locationEvent?.location);

export const selectIsWireless = (callId: string) =>
    createSelector(selectStandardLocation(callId), (standardLocation) => Boolean(standardLocation?.geoPoints?.some((geoPoint) => geoPoint.certainty)));

export const selectLocationDisplayTemplate_ = createSelector(selectLocationState, (state) => state.layoutConfiguration);
export const selectLocationDisplayTemplate = pipe(
    select(selectLocationDisplayTemplate_),
    filter((val) => !!val),
    map((val) => val as LocationDisplayTemplate)
);

export const selectManualLocationNumber = createSelector(selectLocationState, (state) => state.manualLocationNumber);
export const selectManualLocation = createSelector(selectLocationState, (state) => state.manualLocationEvent);
export const selectManualLocationPending = createSelector(selectLocationState, (state) => state.manualLocationPending);
export const selectIsManualLocationWireless = createSelector(selectManualLocation, (location) =>
    Boolean(location?.location?.geoPoints.some((geoPoint) => geoPoint.certainty))
);

export const selectServiceResponders = createSelector(selectLocationState, (state) => state.serviceResponders);

export const selectServiceRespondersUrnMap = createSelector(selectServiceResponders, (serviceResponders) =>
    serviceResponders?.serviceResponderUrns.reduce<Record<string, ServiceResponderUrn>>(
        (map, serviceResponderUrn) => ({ ...map, [serviceResponderUrn.urn]: serviceResponderUrn }),
        {}
    )
);

export const selectServiceRespondersEsnMap = createSelector(selectServiceResponders, (serviceResponders) =>
    serviceResponders?.serviceResponderEsns.reduce<Record<string, ServiceResponderEsn>>(
        (map, serviceResponderEsn) => ({ ...map, [serviceResponderEsn.esn]: serviceResponderEsn }),
        {}
    )
);
