/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpClient } from '@angular/common/http';
import { ServiceName } from '../../../assets/config/environment';
import { KeycloakHealthService } from './keycloak-health.service';

@Injectable({ providedIn: 'root' })
export class KeycloakOidcProviderService {
    public initialized: Promise<any>;
    private keycloakHealthService: KeycloakHealthService;

    constructor(protected http: HttpClient, protected env: EnvironmentService) {
        this.initialized = env.loadConfiguration().then((environment) => {
            if (!environment.featureFlags?.ccAdminIntegrated) {
                let serviceUrls = environment.SERVICES[ServiceName.IDM] ? environment.SERVICES[ServiceName.IDM] : [environment.AUTH_URL];
                let healthEndpoint = `/realms/${environment.AUTH_REALM}/realm-info-endpoints/realm-health`;
                this.keycloakHealthService = new KeycloakHealthService(http, serviceUrls, healthEndpoint);
                return this.keycloakHealthService.initialized;
            }
            return Promise.resolve(true);
        });
    }

    public getRealmUrl(url: string) {
        if (url.charAt(url.length - 1) == '/') {
            return url + 'realms/' + encodeURIComponent(this.env.environment.AUTH_REALM);
        } else {
            return url + '/realms/' + encodeURIComponent(this.env.environment.AUTH_REALM);
        }
    }

    get authorization_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/auth';
    }
    get token_endpoint() {
        return this.getRealmUrl(this.keycloakHealthService?.activeUrl$.value) + '/protocol/openid-connect/token';
    }
    get end_session_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/logout';
    }
    get check_session_iframe() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/login-status-iframe.html';
    }
    get userinfo_endpoint() {
        return this.getRealmUrl(this.env.environment.AUTH_URL) + '/protocol/openid-connect/userinfo';
    }
}
