/*
 * *****************************************************************************
 *     Copyright (C) 2024 Motorola Solutions, Inc.
 *     All Rights Reserved.
 *     Motorola Solutions Confidential Restricted.
 * *****************************************************************************
 */

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ServiceHealth } from '../model/health';
import { BehaviorSubject, interval, Observable, of, throwError } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';
import { ServiceName } from '../../../assets/config/environment';

export abstract class HealthService {
    public initResolve: Function;
    public initialized = new Promise((resolve) => this.initResolve = resolve);
    protected serviceName: ServiceName;
    protected pingInterval = 10000;
    protected activeUrlIndex = 0;
    protected urls: string[] = [];

    public activeUrl$ = new BehaviorSubject<string>('');

    protected constructor(
        protected http: HttpClient,
        protected name: ServiceName,
        protected endpoints: string[],
        protected healthEndpoint = '/actuator/health/readiness'
    ) {
        this.serviceName = name;
        this.urls = endpoints as string[];
        this.activeUrl$.next(this.urls[0]);
        this.initializeHealthChecks();
    }

    protected initializeHealthChecks() {
        interval(this.pingInterval).pipe(
            startWith(0),
            switchMap(() => this.pingUrls(this.serviceName, this.urls))
        ).subscribe({
            error: (err: HttpErrorResponse) => {
                console.error(`${this.serviceName} Service Health Error: ${err.message}`);
            }
        });
    }

    protected pingUrls(serviceName: string, urls: string[]): Observable<ServiceHealth> {
        return this.pingUrl(urls[this.activeUrlIndex]).pipe(
            tap(() => this.initResolve()),
            catchError((err) => {
                this.setActiveIndex((this.activeUrlIndex + 1) % urls.length);
                console.debug(`${serviceName} Health Check failed. ${err.message} Incrementing URL index`, this.activeUrlIndex);
                return of(err);
            })
        );
    }

    protected setActiveIndex(newIndex: number) {
        this.activeUrlIndex = newIndex;
        if (this.activeUrl$.value !== this.urls[newIndex]) {
            this.activeUrl$.next(this.urls[newIndex]);
        }
    }

    protected pingUrl(url: string) {
        return this.http.get<ServiceHealth>(url.concat(this.healthEndpoint)).pipe(
            switchMap((res) => {
                if (res?.status === 'DOWN') {
                    return throwError(() => ({ message: 'Service down' }));
                }

                return of(res);
            }),
            catchError((err) => {
                return throwError(() => err);
            })
        );
    }
}
