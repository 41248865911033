/*
 * COPYRIGHT Motorola Solutions, INC.
 * ALL RIGHTS RESERVED.
 * MOTOROLA SOLUTIONS CONFIDENTIAL RESTRICTED
 */

import { createAction, props } from '@ngrx/store';
import { AsteriskPasswordRevokedEvent, CallQueue, Configuration } from 'CalltakingCoreApi';
import { HotKey } from '../../core/model/hotkey';
import { UiSettings } from '../model/ui-settings';
import { ExternalApplication } from '../../main/application-links/model/external-application';
import { LayoutSettings, Role } from '../model/layout-settings';
import { AudibleAlerts, CallAlert } from '../../call/model/audible-alert';
import { ClusterQuiescence } from '../../call/model/cluster-quiescence';

export const configurationEffectsInitialized = createAction('[Configuration] Effects Initialized');

export const fetchCtcConfiguration = createAction('[Configuration] CTC Fetch');
export const fetchCtcConfigurationSuccess = createAction('[Configuration] CTC Fetch Success', props<{ config: Configuration }>());
export const fetchCtcConfigurationFail = createAction('[Configuration] CTC Fetch Fail', props<{ payload: string }>());

export const fetchCtcTime = createAction('[Configuration] CTC Fetch Time');
export const fetchCtcTimeSuccess = createAction('[Configuration] CTC Fetch Time Success', props<{ offset: number }>());
export const fetchCtcTimeFail = createAction('[Configuration] CTC Fetch Time Fail', props<{ payload: string }>());
export const ctcTimeUpdate = createAction('[Configuration] CTC Time Sync Update', props<{ offset: number }>());

export const fetchUiConfiguration = createAction('[Configuration] UI Fetch');
export const fetchUiConfigurationSuccess = createAction('[Configuration] UI Fetch Success', props<{ config: UiSettings }>());
export const fetchUiConfigurationFail = createAction('[Configuration] UI Fetch Fail', props<{ payload: string }>());

export const fetchCtcVersion = createAction('[Configuration] CTC Fetch Version', props<{ clusterName: string }>());
export const fetchCtcVersionSuccess = createAction('[Configuration] CTC Fetch Version Success', props<{ clusterName: string, version: string }>());
export const fetchCtcVersionFail = createAction('[Configuration] CTC Fetch Version Fail', props<{ payload: string }>());

export const fetchAudibleAlerts = createAction('[Configuration] Fetch Alerts');
export const fetchAudibleAlertsSuccess = createAction('[Configuration] Fetch Alerts Success', props<{ alerts: AudibleAlerts }>());
export const fetchAudibleAlertsFail = createAction('[Configuration] Fetch Alerts Fail', props<{ payload: string }>());

export const fetchCallAlerts = createAction('[Configuration] Fetch Call Alerts');
export const fetchCallAlertsSuccess = createAction('[Configuration] Fetch Call Alerts Success', props<{ alerts: CallAlert[] }>());
export const fetchCallAlertsFail = createAction('[Configuration] Fetch Call Alerts Fail', props<{ payload: string }>());
export const callAlertUpdate = createAction('[Configuration] Update Call Alert', props<{ callAlert: CallAlert }>());
export const callAlertDelete = createAction('[Configuration] Delete Call Alert', props<{ callAlert: CallAlert }>());

export const fetchLayoutConfiguration = createAction('[Configuration] Layout Fetch');
export const fetchLayoutConfigurationSuccess = createAction('[Configuration] Layout Fetch Success', props<{ config: LayoutSettings }>());
export const fetchLayoutConfigurationFail = createAction('[Configuration] Layout Fetch Fail', props<{ payload: string }>());

export const fetchApplicationLinks = createAction('[Configuration] Application Links Fetch');
export const fetchApplicationLinksSuccess = createAction('[Configuration] Application Links  Fetch Success', props<{ apps: ExternalApplication[] }>());
export const fetchApplicationLinksFail = createAction('[Configuration] Application Links  Fetch Fail', props<{ payload: string }>());

export const fetchQueueConfiguration = createAction('[Configuration] Queue Fetch');
export const fetchQueueConfigurationSuccess = createAction('[Configuration] Queue Fetch Success', props<{ queues: CallQueue[] }>());
export const fetchQueueConfigurationFail = createAction('[Configuration] Queue Fetch Fail', props<{ payload: string }>());

export const updateCallQueueFilter = createAction('[Configuration] Update Call Queue Filter', props<{ selectedCallQueues: string[] }>());

export const fetchHotkeys = createAction('[Configuration] Hotkey Fetch');
export const fetchHotkeysSuccess = createAction('[Configuration] Hotkey Fetch Success', props<{ hotkeys: HotKey[] }>());
export const fetchHotkeysFail = createAction('[Configuration] Hotkey Fetch Fail', props<{ payload: string }>());
export const hotKeyEvent = createAction('[Main] Hotkey Event', props<{ hotkey: HotKey }>());
export const hotKeyTriggered = createAction('[Main] Hotkey Triggered', props<{ hotkey: HotKey }>());

export const hasInitialized = createAction('[Main] Finished Initializing');

export const techWebsocketDisable = createAction('[Technician] Disable Websocket');
export const techWebsocketDisableSuccess = createAction('[Technician] Disable Websocket Success');
export const techWebsocketDisableFailure = createAction('[Technician] Disable Websocket Failure');

export const techWebsocketEnable = createAction('[Technician] Enable Websocket');
export const techWebsocketEnableSuccess = createAction('[Technician] Enable Websocket Success');
export const techWebsocketEnableFailure = createAction('[Technician] Enable Websocket Failure');

export const fetchAvailableLanguages = createAction('[Configuration] Fetch Available Languages');
export const fetchAvailableLanguagesSuccess = createAction('[Configuration] Fetch Available Languages Success', props<{ languages: string[] }>());
export const fetchAvailableLanguagesFailure = createAction('[Configuration] Fetch Available Languages Failure', props<{ payload: string }>());

export const fetchQuiescenceState = createAction('[Configuration] Fetch Cluster Quiescence State', props<{ clusterName: string }>());
export const fetchQuiescenceStateSuccess = createAction('[Configuration] Fetch Cluster Quiescence State Success', props<{ clusterName: string; quiescence: ClusterQuiescence }>());
export const fetchQuiescenceStateFail = createAction('[Configuration] Fetch Cluster Quiescence State Fail', props<{ payload: string }>());
export const clusterQuiescence = createAction('[Configuration] Cluster Quiescence State', props<{ quiescence: ClusterQuiescence }>());

export const sipPeerRequest = createAction('[Media Connection] CTC Sip Peer Request', props<{ clusterName: string }>());
export const sipPeerRequestSuccess = createAction(
    '[Media Connection] CTC Sip Peer Request Success',
    props<{ uuid: string; clusterName: string; uri: string; contact_uri: string }>()
);
export const sipPeerRequestFailed = createAction(
    '[Media Connection] CTC Sip Peer Request Failed',
    props<{ uuid: string; clusterName: string; payload: string }>()
);

export const sipPeerPasswordRequest = createAction('[Media Connection] CTC Sip Peer Password Request', props<{ clusterName: string }>());
export const sipPeerPasswordRequestSuccess = createAction(
    '[Media Connection] CTC Sip Peer Password Request Success',
    props<{ uuid: string; clusterName: string; password: string }>()
);
export const sipPeerPasswordRequestFailed = createAction(
    '[Media Connection] CTC Sip Peer Request Password Failed',
    props<{ uuid: string; clusterName: string; payload: string }>()
);

export const sipPeerPasswordRevokedEvent = createAction('[Media Connection] CTC Sip Peer Password Revoked', props<{ event: AsteriskPasswordRevokedEvent }>());

export const heartbeatRequest = createAction('[Media Connection] CTC Heartbeat', props<{ clusterName: string }>());
export const heartbeatRequestSuccess = createAction('[Media Connection] CTC Heartbeat Success', props<{ clusterName: string }>());
export const heartbeatRequestFailed = createAction('[Media Connection] CTC Heartbeat Failed', props<{ clusterName: string }>());
export const ctcHeartBeatOverride = createAction('[Technician] Heartbeat override', props<{ clusterName: string; override: boolean }>());
export const requestPrimaryDataConnectionChange = createAction('[Technician] Request Primary Data Connection Change', props<{ clusterName: string }>());

export const fetchUserSelectableRoles = createAction('[User] Fetch User Selectable Roles');
export const fetchUserSelectableRolesSuccess = createAction('[User] Fetch User Selectable Roles Success', props<{ roles: Role[] }>());
export const fetchUserSelectableRolesFail = createAction('[User] Fetch User Roles Fail', props<{ payload: string }>());
