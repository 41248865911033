/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { OutputDeviceInterface } from './output-device-interface';

export class SingleChannelOutputDeviceInterface extends OutputDeviceInterface {
    protected channelMergerNode: ChannelMergerNode;
    constructor(mediaDeviceInfo: MediaDeviceInfo, channel: number) {
        super(mediaDeviceInfo, channel);
    }

    protected override connect() {
        super.connect();
        this.channelMergerNode = new ChannelMergerNode(this.audioContext, {
            numberOfInputs: 4,
            channelInterpretation: "discrete",
            channelCountMode: "explicit"
        });
        this.mixerNode.connect(this.channelMergerNode, 0, this.channel - 1);
        this.audioContext.destination.channelCount = 4;
        this.audioContext.destination.channelInterpretation = 'discrete';
        this.channelMergerNode.connect(this.audioContext.destination);
    }
}
